import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CardProject from "../components/CardProject";
import ContactsBlock from "../components/ContactsBlock";

import SvgDivider2 from "../images/dividers/divider-2.inline.svg";

import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
import DecorationDots5 from "../images/decorations/deco-dots-5.svg";
import { UxProjectsJsonLd } from "../components/jsonld/UxProjectsJsonLd";
// import DecorationBlob17 from "../images/decorations/deco-blob-17.svg";

const ProjectsPage = ({ data, location: { pathname } }) => {
  const ProjectCardsData = data.allDatoCmsProject.edges;

  return (
    <Layout
      isHeaderDark={true}
      headerBgClass="bg-primary-3"
      meta={{ seo: data.datoCmsPage?.seo, path: pathname }}
    >
      <UxProjectsJsonLd />
      <div className="projects-page">
        <section className="bg-primary-3 has-divider text-light o-hidden">
          <div className="container layer-2 pb-2">
            <div className="row">
              <div className="col-10 mx-auto text-center">
                <h1 className="display-4 pb-5">
                  We Help Big Brands and Startups Improve their Digital Product
                </h1>

                <p className="lead">
                  We’re dedicated to helping businesses of all sizes{" "}
                  <strong>transform their digital product</strong> and deliver a{" "}
                  <strong>stronger user experience. </strong>
                  Our cutting-edge, visionary UX design has helped some of the
                  biggest brands in the world, as well as up-and-coming
                  companies, offer a <strong>more powerful product.</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="divider">
            <SvgDivider2 />
          </div>

          <div className="decoration-wrapper d-none d-lg-block">
            <div
              className="decoration scale-1 "
              style={{ top: "30%", right: -30 }}
            >
              <DecorationBlob6 className="bg-yellow" />
            </div>

            <div className="decoration scale-1 left" style={{ bottom: 40 }}>
              <DecorationDots5 className="bg-primary" />
            </div>
          </div>
        </section>

        <section className="pb-1">
          <div className="container">
            <div className="row">
              {ProjectCardsData.map(({ node }) => (
                <div className="col-md-6 d-flex" key={node.id}>
                  <CardProject cardData={node}/>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/*<section className="mb-7 o-hidden pos-r">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <a href="/">
                  <div className="card card-body bg-primary border-0 text-light o-hidden">
                    <div className="h4 text-center mb-0 py-2">Download Case Studies as PDF</div>

                    <div className="decoration-wrapper d-none d-lg-block">
                      <div className="decoration" style={{ right: 40, bottom: -60 }}>
                        <DecorationBlob17 className="bg-primary-3" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>*/}
        <div className="mb-7" />
      </div>

      <ContactsBlock isInViewProp={true} />
    </Layout>
  );
};

export default ProjectsPage;

export const query = graphql`
  query ProjectsPageQuery {
    datoCmsPage(slug: { eq: "ux-projects" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    allDatoCmsProject(
      filter: { hiddenProject: { ne: true } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          cardImage {
            gatsbyImageData(layout: CONSTRAINED, width: 1200)
          }
          logo {
            url
          }
          cardColor {
            hex
          }

          cardDescription
          categoryProject
          industry

          slug
          id
          secretProject
        }
      }
    }
  }
`;

import React from "react";
import { Helmet } from "react-helmet";

export const UxProjectsJsonLd = () => {
  const profServiceJson = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    name: "We Help Big Brands and Startups Improve their Digital Product",
    description:
      "We’re dedicated to helping businesses of all sizes transform their digital product and deliver a stronger user experience. Our cutting-edge, visionary UX design has helped some of the biggest brands in the world, as well as up-and-coming companies, offer a more powerful product.",
    disambiguatingDescription: "Our UX Design Projects & Case Studies",
    url: "https://adamfard.com/ux-projects",
    openingHours: ["Mon-Fri: 24/7"],
    telephone: "no",
    foundingDate: "2016",
    paymentAccepted: [
      "Visa",
      "Cash",
      "Master Card",
      "Credit Card",
      "Cash",
      "American Express"
    ],
    priceRange: "$$",
    image: {
      "@type": "ImageObject",
      url: [
        "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png",
        "https://www.datocms-assets.com/16499/1594154933-qolo-thumbnail-fintech.png",
        "https://www.datocms-assets.com/16499/1592924175-ciphy.png",
        "https://www.datocms-assets.com/16499/1573911453-startlingux.png",
        "https://www.datocms-assets.com/16499/1573911982-samsungux.png",
        "https://www.datocms-assets.com/16499/1573910711-morecastux.png",
        "https://www.datocms-assets.com/16499/1578152525-dtappux.png",
        "https://www.datocms-assets.com/16499/1573915833-cityrideux.png"
      ],
      height: 800,
      width: 1200
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "Neue Schönhauser Straße 3",
      addressLocality: "Berlin",
      addressRegion: "BE",
      postalCode: "10178",
      areaServed: "Germany"
    },
    award: [
      "Top Empathetic 2020",
      "Best UX design in Berlin",
      "2020 Best User testing",
      "Top UX research",
      "Top UX Agency & B2B company",
      "Top Web Design Company",
      "Featured UX Studio"
    ],
    brand: [
      "UX designer",
      "User testing",
      "Competitive analysis",
      "Design App",
      "What is ux design",
      "What is agile",
      "Redesigning",
      "UX research",
      "Technology in education",
      "Information architecture",
      "Dashboard design",
      "UX writing",
      "Heuristic evaluation",
      "Organizing workshops",
      "How to app design",
      "Product idea",
      "Design process UX",
      "Lean UX",
      "Dashboards UI",
      "UI dashboard",
      "Design libraries",
      "Heuristics analysis",
      "Prototypes design",
      "UX strategy",
      "Information architecture UX",
      "UI UX design agency"
    ],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      email: "contact@adamfard.com",
      availableLanguage: "en"
    },
    areaServed: ["Germany", "USA"],
    knowsAbout: [
      "UX design for startups",
      "UX design agency",
      "UX design blog",
      "Edtech startups",
      "Agile design",
      "App design process",
      "App monetization",
      "App redesign",
      "Future of augmented reality",
      "Technology and education",
      "UX research methods",
      "Value of ux design",
      "How to choose brand colors",
      "Best chatbot",
      "Competitive market analysis",
      "What is user testing",
      "Contextual inquiry UX",
      "Customer experience strategy",
      "Customer journey template",
      "Dashboard ui design",
      "UX design system",
      "Design thinking process",
      "Design thinking workshop",
      "Design validation",
      "Design bias",
      "Design ops",
      "User experience testing",
      "UX best practices",
      "Edtech trends",
      "App designer education",
      "Design thinking empathy",
      "Enterprise software UX design",
      "Fintech App",
      "Fintech UX Design",
      "UX Gamification",
      "Goodfirms",
      "App development mistakes",
      "Heuristic evaluation",
      "Hire a UX Designer",
      "Product validation",
      "New business startup ideas",
      "User behavior",
      "Information architecture UX",
      "Lean UX design",
      "Usability Metrics",
      "Fintech trends",
      "Persuasive Design",
      "Pitch Decks",
      "Product prototype",
      "UX prototyping",
      "Quibi app review",
      "Racial discrimination in the workplace",
      "Remote team building",
      "Retention Rate",
      "Stakeholder interview questions",
      "Funding strategies for startups",
      "App stickiness",
      "Design style guide",
      "Trello review",
      "Understanding User Behavior",
      "Usability testing methods",
      "Usability testing questions",
      "Usability testing tools",
      "User research methods",
      "SaaS Monetization",
      "UX and SEO",
      "UX architect",
      "UX Audit",
      "What Does a UX Designer Do",
      "Onboarding UX checklist",
      "UX strategy",
      "UX testing",
      "UI UX tools",
      "UX workshop",
      "UX writing",
      "Design Validation",
      "Website Redesign",
      "Purpose of wireframes",
      "Business Development Manager",
      "Best UX Design Resources",
      "Customer Experience Strategy",
      "Heuristic evaluation checklist",
      "How to Launch a Digital Product",
      "Startup Pitch Deck",
      "Prototyping in UX",
      "Client questionnaire",
      "Usability Testing Checklist",
      "UX designer interview questions",
      "Create an educational App",
      "Fintech startups",
      "Free strategy session",
      "Marketing specialist",
      "Communications as a Service Platform",
      "City Ride App",
      "B2B payment platform",
      "App testing platform",
      "Morecast Weather App",
      "SaaS ux design",
      "UI ux designer job description",
      "UX Design Process",
      "UX Design Projects"
    ],
    department: "UX and UI Design Studio",
    parentOrganization: "Adarm Fard",
    knowsLanguage: "en-USA",
    logo:
      "https://www.datocms-assets.com/16499/1612210672-cover-usability-testing.png",
    sameAs: [
      "https://www.linkedin.com/company/adam-fard/",
      "https://twitter.com/adamfard_",
      "https://medium.com/@adam.fard/",
      "https://dribbble.com/adamfardstudio",
      "https://www.behance.net/AdamFardStudio/"
    ]
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/ux-projects/#Webpage",
    url: "https://adamfard.com/ux-projects/",
    headline: "We Help Big Brands and Startups Improve their Digital Product",
    primaryImageOfPage: [
      "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1594154933-qolo-thumbnail-fintech.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1592924175-ciphy.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1573911453-startlingux.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1573911982-samsungux.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1573910711-morecastux.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1578152525-dtappux.png?auto=format&dpr=0.69&w=1176",
      "https://www.datocms-assets.com/16499/1573915833-cityrideux.png?auto=format&dpr=0.69&w=1176"
    ],
    lastReviewed: "2021/01/17",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description: "Our UX Design Projects & Case Studies",
    image:
      "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png?auto=format&dpr=0.69&w=1176",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breacrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description: "Our UX Design Projects & Case Studies",
    disambiguatingDescription: "Case Studies and UX Design Projects",
    mainEntityOfPage: "https://adamfard.com/ux-projects/",
    image:
      "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png?auto=format&dpr=0.69&w=1176",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://adamfard.com/ux-projects/",
          name: "UX Projects"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(profServiceJson)}
      </script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breacrumbJson)}
      </script>
    </Helmet>
  );
};
